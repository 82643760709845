import request from '@/utils/request'

/**
 * 获取型号列表
 * @param pram
 */
export const getGraph = (data) => {
    return request({
      url: '/sport/graph',
      method: 'get',
      params: data
    })
}


/**
 * 获取型号列表
 * @param pram
 */
export const getTree = (data) => {
    return request({
      url: '/sport/tree',
      method: 'get',
      params: data
    })
}

// 获取图谱类目列表
export const getCategory = (data) => {
  return request({
    url: '/tree/category',
    method: 'get',
    params: data
  })
}

// 获取图谱子节点
export const getSubNode = (data) => {
  return request({
    url: '/tree/sub/node',
    method: 'get',
    params: data
  })
}

// 获取图谱子节点内容
export const getNodeInfo = (data) => {
  return request({
    url: '/tree/node/info',
    method: 'get',
    params: data
  })
}

